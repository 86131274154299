import { Column } from '@devexpress/dx-react-grid';

export const EMAIL_REPORT ="Email Report"
export const EMAIL_INFO_MESSAGE ="Displays the Email report for the date range provided"

export const columns: Column[] = [
  { name: 'repairerName', title: 'Repairer Name' },
  { name: 'repairerGroup', title: 'Repairer Group' },
  { name: 'schemeCode', title: 'Scheme Code' },
  { name: 'workProvider', title: 'Work Provider' },
  { name: 'regmark', title: 'Vehicle Reg.' },
  { name: 'emailSentDate', title: 'Email Date and Time' },
  { name: 'subject', title: 'Email Subject' },
];

export const tableColumnExtensions = [
  { columnName: 'repairerName', wordWrapEnabled: true },
  { columnName: 'repairerGroup', wordWrapEnabled: true },
  { columnName: 'schemeCode', wordWrapEnabled: true },
  { columnName: 'workProvider', wordWrapEnabled: true },
  { columnName: 'regmark', wordWrapEnabled: true },
  { columnName: 'emailSentDate', wordWrapEnabled: true },
  { columnName: 'subject', wordWrapEnabled: true },
];

export const summaryItems = [{ columnName: 'repairerName', type: 'count' }];

export const dateColumnsName = ['emailSentDate'];
